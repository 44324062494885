<template>
  <b-card>
    <b-card-title>Création d'une licence utilisateur</b-card-title>
    <b-form
      @submit.prevent
      @submit="createUser(userData)"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Email et pseudo"
            label-for="h-email"
            label-cols-md="4"
          >
            <b-form-input
              id="h-email"
              v-model="userData.email"
              type="email"
              placeholder="Email"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Prénom"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-input
              id="h-first-name"
              v-model="userData.firstname"
              placeholder="Prénom"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Nom"
            label-for="h-last-name"
            label-cols-md="4"
          >
            <b-form-input
              id="h-last-name"
              v-model="userData.lastname"
              placeholder="Nom"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Téléphone"
            label-for="h-number"
            label-cols-md="4"
          >
            <b-form-input
              id="h-number"
              v-model="userData.phoneNumber"
              type="text"
              placeholder="Numéro"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Entreprise"
            label-for="h-enterprise"
            label-cols-md="4"
          >
            <v-select
              v-model="userData.enterprise"
              :options="enterpriseOptions.filter(onlyUnique).sort()"
              taggable
              push-tags
              label="Entreprise"
            >
              <template #search="{attributes, events}">
                <!-- eslint-disable-next-line -->
                <input
                  class="vs__search"
                  :required="!userData.enterprise"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Mot de passe"
            label-for="h-password"
            label-cols-md="4"
          >
            <b-input-group>
              <b-form-input
                id="h-password"
                v-model="userData.password"
                :type="passwordFieldType"
                placeholder="Mot de passe"
                required
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon()"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="8"
          offset-md="4"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="preferences.fullDataUploadRequired"
              checked="false"
              name="h-upload"
              switch
              inline
            >
              Force upload data
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          md="8"
          offset-md="4"
        >
          <b-form-group
            label="Channel"
            label-for="h-channel"
          >
            <b-form-checkbox
              v-model="preferences.channel"
              name="h-channel"
              value="prod"
            >
              prod
            </b-form-checkbox>
            <b-form-checkbox
              v-model="preferences.channel"
              name="h-channel"
              value="beta"
            >
              beta
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          md="8"
          offset-md="4"
        >
          <b-form-group
            label="Scopes"
            label-for="h-scopes"
          >
            <b-form-checkbox
              v-model="scopes"
              name="h-scopes"
              value="user"
            >
              user
            </b-form-checkbox>
            <b-form-checkbox
              v-model="scopes"
              name="h-scopes"
              value="admin"
            >
              admin
            </b-form-checkbox>
            <b-form-checkbox
              v-model="scopes"
              name="h-scopes"
              value="superadmin"
            >
              superadmin
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Sauvegarder
          </b-button>
          <b-link
            :to="{ name: 'users' }"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-primary"
            >
              Annuler
            </b-button>
          </b-link>
        </b-col>
      </b-row>

    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
  BCard, BCardTitle, BLink, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BCardTitle,
    BLink,
    vSelect,
    BInputGroupAppend,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userData: {},
      preferences: {
        channel: [],
        fullDataUploadRequired: false,
      },
      scopes: [],
      enterpriseOptions: [],
    }
  },
  mounted() {
    this.hydrateUserEnterprise()
  },
  methods: {
    async createUser(userData) {
      const newUser = userData
      newUser.username = userData.email
      newUser.preferences = btoa(JSON.stringify(this.preferences))
      const credentialData = {
        credential: {
          scopes: this.scopes,
          keyId: newUser.username,
          keySecret: newUser.password,
        },
        consumerId: newUser.username,
        type: 'key-auth',
      }
      await this.$store.dispatch('user/createUser', newUser)
      await this.$store.dispatch('user/createCredentials', credentialData)
        .then(response => this.$router.push({ name: 'users-view', params: { id: response.data.consumerId } }))
    },
    hydrateUserEnterprise() {
      this.$store.dispatch('user/findAllUsers')
        .then(response => {
          response.forEach(user => {
            this.enterpriseOptions.push(user.enterprise)
          })
        })
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
